<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    :max-width="maxWidth"
    :transition="transition"
    :scrollable="scrollable"
    content-class="custom-dialog-content"
  >
    <v-card :min-height="minHeight" class="pa-5 px-sm-10" :rounded="rounded" :color="innerBg">
      <div v-if="isShowClose" class="text-end">
        <v-btn text color="red" @click="close">
          <v-icon size="16" left>mdi-close</v-icon>
          {{ $t('button.close') }}
        </v-btn>
      </div>
      <slot />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CustomDialog',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [String, Number],
      default: 620,
    },
    minHeight: {
      type: [String, Number],
      default: 300,
    },
    isShowClose: {
      type: Boolean,
      default: true,
    },
    transition: {
      type: String,
      default: 'scroll-y-transition',
    },
    innerBg: {
      type: String,
      default: '',
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: [String, Boolean],
      default: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    close() {
      this.show = false
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss">
.custom-dialog-content {
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-blue-base);
    border-radius: 5px;
    visibility: hidden;
    //border-left: 2px solid var(--v-blue-base);
  }

  &::-webkit-scrollbar {
    border-radius: 5px;
    width: 5px;
  }

  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}
</style>
