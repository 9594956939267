<template>
  <v-row class="justify-space-between align-center mb-15">
    <v-col cols="12" sm="5" md="3">
      <CustomSelect
        v-if="selectedParam.type === 'select'"
        v-model.trim="selectedParam.value"
        append-icon="mdi-magnify"
        :items="mapSelectItems(selectedParam.name)"
        :placeholder="$t('label.' + selectedParam.placeholder)"
        hide-details
        :outlined="false"
        class="mr-3"
        @change="$emit('add')"
      />
      <CustomAutocomplete
        v-else-if="selectedParam.type === 'autocomplete'"
        v-model.trim="selectedParam.value"
        :items="mapSelectItems(selectedParam.name)"
        :placeholder="$t('label.' + selectedParam.placeholder)"
        hide-details
        :outlined="false"
        :clearable="false"
        :smallChips="false"
        class="mr-3"
        :return-object="selectedParam.isObjectValue"
        @change="$emit('add')"
      />
      <CustomInput
        v-else
        v-model.trim="selectedParam.value"
        append-icon="mdi-magnify"
        :placeholder="$t('label.' + selectedParam.placeholder)"
        hide-details
        :outlined="false"
        class="mr-5"
        @keydown.enter="$emit('add')"
      />
    </v-col>
    <v-col v-if="selectedFilters.length" cols="12" sm="7">
      <CustomChip
        v-for="param in selectedFilters"
        :key="param.name"
        class="mr-3"
        @click:close="$emit('delete', param)"
      >
        {{ parseSelectValue(param) }}
      </CustomChip>
    </v-col>
    <v-col cols="12" sm="5" md="2" class="d-md-flex justify-end">
      <ActionButton color="red" icon="mdi-delete" @click="$emit('clear')">
        {{ $t('button.clear') }}
      </ActionButton>
    </v-col>
  </v-row>
</template>
<script>
import substringLength from '@/helpers/substringLength'

export default {
  name: 'CustomTableFilter',
  props: {
    type: {
      type: String,
      default: '',
    },
    selectedParam: {
      type: Object,
      default: () => {},
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
    statusItems: {
      type: Array,
      default: () => [],
    },
    statusBidderItems: {
      type: Array,
      default: () => [],
    },
    campaignTypeItems: {
      type: Array,
      default: () => [],
    },
    ruleTypeItems: {
      type: Array,
      default: () => [],
    },
    ruleTypeRangeItems: {
      type: Array,
      default: () => [],
    },
    userNameItems: {
      type: Array,
      default: () => [],
    },
    categoryItems: {
      type: Array,
      default: () => [],
    },
    versionItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    substringLength,
    parseSelectValue(item) {
      const {value, isObjectValue} = item
      if (isObjectValue) {
        return substringLength(value.text)
      } else {
        return substringLength(value)
      }
    },
    mapSelectItems(name) {
      switch (name) {
        case 'status':
          return this.statusItems
        case 'bidderStatus':
          return this.statusBidderItems
        case 'campaignType':
          return this.campaignTypeItems
        // TODO: если название будут повторяться то передавать и привязаться из пропсов к type
        case 'type':
          return this.ruleTypeItems
        case 'timeRange':
          return this.ruleTypeRangeItems
        case 'userId':
          return this.userNameItems
        case 'category':
          return this.categoryItems
        case 'rtbVersion':
          return this.versionItems
        default:
          return []
      }
    },
  },
}
</script>
